/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body, html {
    height: 100%;
}
body {
    font-family: Poppins,sans-serif;
    display: flex;
    width: 100%;
    /* background-color: #f7f8fc; */
    background-color: #f1f6fe;
    color: #07285b;
    scroll-behavior: smooth;
}
button {
    font-weight: 500 !important;
    transition: 0.2s ease-in-out;
}
app-root {
    display: flex;
    flex-direction: column;
}
app-home, app-login, app-root {
    width: 100%;
}
@media (min-width: 769px) {
    .flex-on-desktop {
        flex:1;
    }
}
.card- {
    &header, &footer {
        background-color: unset;
        border: none;
    }
}
.nav-link {
    cursor: pointer;
}
.text-muted {
    --bs-text-opacity: 1;
    color: #779 !important;
}
.fw-bold {
    font-weight: 600!important;
}
@media screen and (max-width: 991px) {
    #sidebar {
        width: 103px !important;
    }
    .nav-item-text {
        display: none !important;
    }
    .collapse-btn {
        pointer-events: none;
    }
}
.round-icon {
    width: 80px;
    height: 80px;
    background-color: #e1e1e1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-sm {
        width:40px !important;
        height: 40px !important;
    }
}

.card {
    // --bs-card-border-color: rgb(0 0 0 / 0%);
    --bs-card-border-color: rgb(0 0 0 / 0%);
    border: 1px solid #e2e4f5;
}

.shadow-sm {
    // box-shadow: unset !important;
    box-shadow: 0 2px 5px 0 rgba(0,0,100,.1) !important;
}
.rounded-4 {
    border-radius: 14px !important;
    // border: 0;
    // border-radius: 4px !important;
}
.rounded-1 {
    border-radius: var(--bs-border-radius-lg)!important;
}
.custom-link {
    color: #0d6efd;
    cursor: pointer;
    &:hover {
        color: #0a53be;
    }
}
select, input, textarea {
    &:not(.disabled) {
        // background-color: #f5f9ff;
        background-color: #edf2fa;
    }
}
.form-control {
    &:visited {
        background-color: red !important;
    }
    &:focus, &:active {
        background-color: #fff !important;
    }
    &:focus {
    }
}
.form-floating .form-control {
    &:focus {
        border: 2px solid #07285b !important;
        box-shadow:unset !important;

    }
}
.btn-secondary {
    --bs-btn-color: #494d55;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #07285b;
    --bs-btn-hover-bg: #e1ebfc;
    --bs-btn-hover-border-color: #e1ebfc;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #d7e3f5;
    --bs-btn-active-border-color: #d7e3f5;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #5d7395;
    --bs-btn-disabled-bg: #dfe1e3;
    --bs-btn-disabled-border-color: #dfe1e3;
    // --bs-btn-color: #3783f5;
    // --bs-btn-bg: #ebf3fe;
    // --bs-btn-border-color: #ebf3fe;
    // --bs-btn-hover-color: #3783f5;
    // --bs-btn-hover-bg: #d7e3f5;
    // --bs-btn-hover-border-color: #d7e3f5;
    // --bs-btn-focus-shadow-rgb: 130,138,145;
    // --bs-btn-active-color: #fff;
    // --bs-btn-active-bg: #d7e3f5;
    // --bs-btn-active-border-color: #d7e3f5;
    // --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    // --bs-btn-disabled-color: #5d7395;
    // --bs-btn-disabled-bg: #dfe1e3;
    // --bs-btn-disabled-border-color: #dfe1e3;
}

    .table {
     --bs-table-color: #07285b;
    }
.delete-mask {
    color: #cdbbbb;
    &-text {
        display: none;
        transition: 0.5 ease;
    }
    &:hover {
        color: #c15858;
        .delete-mask-text {
            display: unset;
        }
    }
}
.collapse-btn {
    position: absolute;
    z-index: 1;
    transform: translateY(-14px) rotate(180deg);
    cursor: pointer;
    // box-shadow: 0px 3px 10px -3px;
    box-shadow: 0px 3px 2px -3px;
    border-radius: 100%;
    transition: 0.7s ease;
    &:hover {
        opacity: 0.8;
    }
    &.rotate {
        transition: 0.7s ease;
        transform: translateY(-14px) rotate(0deg);
    }
}
.nav-item-text {
    transform: 0.5 ease;
}
.bg-light-blue {
    background-color: #ebf3fe;
}
.text-bg-danger {
    color: #c14949!important;
    background-color: #ffc4c4 !important;
}
.text-bg-success {
    color: green !important;
    background-color: #d8f3d8 !important;
}
.text-secondary {
    --bs-text-opacity: 1;
    color: #5c7c86 !important;
    
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3171d6;
    --bs-btn-border-color: #3171d6;
    --bs-btn-hover-color: #07285b;
    --bs-btn-hover-bg: #e1ebfc;
    --bs-btn-hover-border-color: #e1ebfc;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #07285b;
    --bs-btn-active-border-color: #07285b;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #475b7f;
    --bs-btn-disabled-bg: #c3cfe2;
    --bs-btn-disabled-border-color: #c3cfe2;
    transition: 0.2s ease-in-out;
    // --bs-btn-color: #fff;
    // --bs-btn-bg: #528de5;
    // --bs-btn-border-color: #528de5;
    // --bs-btn-hover-color: #fff;
    // --bs-btn-hover-bg: #0b5ed7;
    // --bs-btn-hover-border-color: #0a58ca;
    // --bs-btn-focus-shadow-rgb: 49,132,253;
    // --bs-btn-active-color: #fff;
    // --bs-btn-active-bg: #0a58ca;
    // --bs-btn-active-border-color: #0a53be;
    // --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    // --bs-btn-disabled-color: #fff;
    // --bs-btn-disabled-bg: #0d6efd;
    // --bs-btn-disabled-border-color: #0d6efd;
}
.list-group-item {
    cursor: pointer;
    &:not(.active):hover {
        background-color: #f8f8fa;
    }
    .unchecked {
        display: block;
        color:#cbcbcb !important;
    }
    .checked {
        display: none;
    }
    &.active {
        z-index: 2;
        color: var(--bs-list-group-active-color);
        background-color: #528de5;
        border-color: #528de5;
        .unchecked {
            display: none !important;
        }
        .checked {
            display: block !important;
        }
    }
}
.list-group-item.active:hover {
    background-color: #4481dd !important;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #50bb9d;
    --bs-btn-border-color: #38ad8c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #43a388;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #43a388;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #70a797;
    --bs-btn-disabled-border-color: #70a797;
}

.badge.text-bg-primary {
    color: #0d6efd!important;
    background-color: #ebf3fe !important;
}
table .aligned {
    display: flex;
    width: max-content;
}
table {
    th, td  {
        text-wrap: nowrap;
    }
}
.list-group-item {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
    cursor: pointer;
    svg {
        margin-right: 0.5rem;
    }
    .chevron {
        visibility: hidden;
    }
    &:hover {
        background-color: #f8f8fa;
        .chevron {
            visibility: unset;
        }
    }
}
.breadcrumb a {
    text-decoration: none;
}
.breadcrumb-item.active {
    width: 500px;
}
.breadcrumb {
    margin-bottom: 0.25rem;
}
.btn-warning {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ff8503;
    --bs-btn-border-color: #ff8503;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ff8503;
    --bs-btn-hover-border-color: #ff8503;
    --bs-btn-focus-shadow-rgb: 217,164,6;
    --bs-btn-active-color: #8f7015;
    --bs-btn-active-bg: #edd394;
    --bs-btn-active-border-color: #edd394;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #e7e0cf;
    --bs-btn-disabled-border-color: #e7e0cf;
}
.avatar {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8eaee;
    border-radius: 100%;
    overflow: hidden;
    color: #07285b;
    &-lg {
        width: 150px;
        height: 150px;
    }
}
.wave-bg {
    background-image: url('/assets/img/wave.png');
    background-size: contain;
    background-position-y: 0;
}
.nav-pills .nav-link.active {
    color: #07285b;
    background-color: #e1ebfc;
    font-weight: 500;
}

.animate__animated {
    animation-duration: 0.2s !important;
}
.pointer {
    cursor: pointer;
}
// .custom-pagination ::ng-deep .ngx-pagination .current {
//     padding: 0.1875rem 0.625rem;
//     background: #3171d6 !important;
//     color: #fefefe;
//     cursor: default;
//     border-radius: 4px;
// }
span {
    &.sub-2 {
        padding-left: 12px;
        // border-right:1px solid lightgray;
    }
    &.sub-3 {
        padding-left: 24px;
        // border-right:1px solid lightgray;
    }
}

.org-row {
    padding: 0.5rem 0;
    &:hover {
        background-color: #f8f8fa;
    }
}
#popup-alert {
    position: fixed;
    right: 60px;
    bottom: 30px;
    width: max-content;
    background-color: #0a1830;
    color: white;
    padding: 12px;
    border-radius: 6px;
    font-size: 18px;
    transition: 0.4s ease-in-out;
    display: none;
    &.show {
        display: block !important;
    }
}

//custom pagination rules
.ngx-pagination{
    margin-bottom:0 !important;
    padding: 0;
}

.ngx-pagination li{
    margin-right: -1px !important;
}
.ngx-pagination li.current{
    background-color: #007bff ;
    border: 1px solid #007bff ;
    padding: 6px 12px !important;
    z-index: 3 !important;
    position: relative;
}
.ngx-pagination li a:focus{
    outline:none;
}
.ngx-pagination a, .ngx-pagination button{
    color: var(--base-text-color) ;
    background-color: var(--card-background-color) ;
    border: 1px solid var(--bs-border-color) ;
    padding: 6px 12px !important;
    &:hover {
        background-color: var(--highlight-over-card-bg) !important;
    }

}

.ngx-pagination .disabled{
    color: #aaaaaa  !important;
    pointer-events: none  ;
    cursor: auto  ;
    background-color: rgb(242 242 242) ;
    border: 1px solid var(--bs-border-color) ;
    padding: 6px 12px !important;
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
    background: #f2f2f2 !important;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after { 
content: '' !important; 
opacity: 0;
margin: 0 !important;
padding: 0 !important;
}

.pill {
    font-size: 14px;
    padding: 2px 12px;
    border-radius: 16px;
    width: max-content;
    margin: 0;
    &.success, &.open {
        background-color: #c5ffe2;
        color: #07285b;
    }
    &.danger {
        background-color: #ffe3e8;
        color: #dc052d;
    }
    &.warning {
        background-color: #fff3cd;
        color: #b56000;
    }
    &.info, &.loading {
        background-color: gray !important;
        color: #000 !important;
    }
    &.loading {
        .spinner-border-sm {
            --bs-spinner-width: 14px;
            --bs-spinner-height: 14px;
            --bs-spinner-border-width: 2px;
        }
    }
    &.closed {
        background-color: #e8eaee;
        color: #07285b;
    }
}

span.checked, span.unchecked {
    font-size: 1rem !important;
}
.dropdown-item {
    cursor: pointer;
}

.list-group-item:hover {
    background-color: transparent !important;
}
